.header-bars-animated {
  padding-top: 250px;
}
.header-bars-animated .page-title {
  margin-bottom: 50px;
  text-align: center;
}
.header-bars-animated h1,
.header-bars-animated h2,
.header-bars-animated p,
.header-bars-animated .fa {
  color: white;
}
