.header-bars-animated {
  padding-top: 250px;

  .page-title{
    margin-bottom: 50px;
    text-align: center;
  }

    h1,h2,p,.fa {
      color: white;
    }

}
